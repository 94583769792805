/* global EM */
import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import Routes from '../../app/Routes';
import StaffingUtil from '../../util/StaffingUtil';
import Dates from '../../util/Dates';
import Spinner from '../Spinner';

export default class EmployeeAvailabilityWidget extends Component{
    constructor(props){
        super(props);

        let domain = EM.getActiveDomain();       
        this.staffingEmpUrl = Routes.compose(Routes.client.staffingEmployee, { DomainName: domain.Name }) + '#';

        this.state = {
            availability: null
        };   
        
        this.count = 15;
    }

    componentDidMount(){
        let self = this;
        self._isMounted = true;

        let disableAvailability = EM.getSetting('DisableEmployeeAvailability');
        if (disableAvailability)return null;

        let range = Dates.getArrayOfMonths(Dates.now(), Dates.now().plus({ months: 1 }), true);

        //Use the row count plus some arbitrary count to determine if there is more availability than the row count. -JS
        StaffingUtil.getLimitedEmployeeAvailability(range, this.count + 1).then(availability => {
            let newAvail = [];
            Object.keys(availability).forEach(key => {
                let av = availability[key];
                if (av.cls !== 'under' || av.average >= 1)return;
                newAvail.push({
                    id: key, 
                    name: EM.employees.lookupValue(key),
                    average: (av.average || 0) * 100,
                });
            })
            
            newAvail = newAvail.sort((a, b) => {
                return a.average - b.average;
            });
            
            if (self._isMounted)this.setState({ availability: newAvail });
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render(){ 
        return (
            <div>
                <h4>{EM.t('widgets.employeeAvailability.title')}</h4>
                <div className="card mt-2 widget">
                    <div className="card-body">
                        <div className="card-text">
                            {this.state.availability ? 
                                <div className="pills">
                                    {this.state.availability.slice(0, this.count).map(item => {
                                        return (
                                            <NavLink key={item.id} to={this.staffingEmpUrl + item.id}
                                                title={item.name + ' - ' + item.average.toFixed(0) + '%'}
                                            >
                                                <b>{item.name}</b>
                                                <i>{item.average.toFixed(0)}%</i>
                                            </NavLink>
                                        );
                                    })}
                                    {this.state.availability.length > this.count ?
                                        <NavLink key={'over'} className="overflow-message" to={this.staffingEmpUrl}>
                                            {EM.t('widgets.employeeAvailability.plus',null)}
                                        </NavLink>
                                    : null }
                                    {this.state.availability.length === 0 ?
                                        <NavLink key={'over'} className="overflow-message" to={this.staffingEmpUrl}>
                                            No availability found.
                                        </NavLink>                                    
                                    : null}
                                </div>                                
                            : <Spinner text={EM.t('util.loading')} classes="small"/>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};