/* global EM */
import React, { Component } from 'react';
import EntityTable from '../components/EntityTables/EntityTable';
import BudgetsTabs from '../components/BudgetsTabs';
import { Validators } from '../util/EntityValidators';
import { Type } from 'react-bootstrap-table2-editor';

class CurrencyConversions extends Component {
    constructor(props) {
        super(props);
        EM.currencyConversions.load();
        EM.currencyCodes.load();
        EM.countryCurrencies.load();
    }

    render() {
        let data = null;
        let columns = null;
        const entity = EM.currencyConversions;

        if (EM.allLoaded(entity, EM.currencyCodes, EM.countryCurrencies)) {
            data = entity.get();

            const countryCurrencyCode = EM.currencyCodes.get();
            const countryCurrencies = EM.countryCurrencies.get();
            const uniqueCurrencies = new Map();

            countryCurrencyCode.forEach(item => {
                if (!uniqueCurrencies.has(item.Code)) {
                    uniqueCurrencies.set(item.Code, { 
                        value: item.CurrencyCodeId, 
                        label: item.Code 
                    });
                }
            });
            
            const options = Array.from(uniqueCurrencies.values());

            const currencyToCountriesMap = countryCurrencies.reduce((acc, item) => {
                if (!acc[item.CurrencyCodeId]) {
                    acc[item.CurrencyCodeId] = [];
                }
                acc[item.CurrencyCodeId].push(item.Country);
                return acc;
            }, {});


            Object.keys(currencyToCountriesMap).forEach(key => {
                currencyToCountriesMap[key] = currencyToCountriesMap[key].join(', ');
            });

            const countryLookup = (cell, row) => {
                return currencyToCountriesMap[row.CurrencyCodeId] || "N/A";
            };

            const currencyCodeLookup = (cell, row) => {
                return EM.currencyCodes.lookupValue(Number(row.CurrencyCodeId), 'Code');
            };

            const filterCurrencyCodeLookup = (cell, row) => {
                return EM.currencyCodes.lookupValue(Number(row.CurrencyCodeId), 'CurrencyCodeId');
            };

            columns = [
                {
                    dataField: 'CurrencyCodeId',
                    text: (entity.columns('CurrencyCode')).split('.').pop(),
                    validators: [Validators.required],
                    formatter: currencyCodeLookup,
                    sortValue: currencyCodeLookup,
                    filterValue: filterCurrencyCodeLookup,
                    csvFormatter: currencyCodeLookup,
                    editor: {
                        type: Type.SELECT,
                        options: options,
                    },
                   filterOptions: {
                        select: true,
                        options: options,
                    },
                },
                {
                    dataField: 'Country',
                    text: "Country",
                    formatter: countryLookup,
                    filterValue: countryLookup,
                    sortValue: countryLookup,
                    isReference: true,
                    filterOptions: {
                        text: true,
                    },
                    editable: false,
                },
                {
                    dataField: 'ConversionRate',
                    text: (entity.columns('ConversionRate')).split('.').pop(),
                    validators: [Validators.required],
                    filterOptions: {
                        text: true,
                    },
                },
            ];
        }

        return (
            <div key="contents" className="page container-fluid">
                <BudgetsTabs {...this.props} />
                <EntityTable
                    entity={entity}
                    data={data}
                    columns={columns}
                    className="table-overflow-container tabs"
                />
            </div>
        );
    }
}

export default CurrencyConversions;
