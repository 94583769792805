/* global EM */
import React, { Component, Suspense } from 'react';
import EntityTable from '../components/EntityTables/EntityTable';
import { Validators } from '../util/EntityValidators';
import ExpressionDialog from '../components/Expressions/ExpressionDialog';
const ExpressionDialog2 = React.lazy(() => import('../components/Expressions/ExpressionDialog2'));

export default class Algorithms extends Component {
    constructor(props){
        super(props);

        this.state ={
            isExpressionEditorOpen: false,
            currentExpressionRow: null,
            currentExpressionEditorProps: null,
            isExpressionValid: true,
            accordionOpen: 'variables'
        }

        EM.algorithms.load();
        EM.activities.load();
        EM.roles.load();
        EM.complexityLookup.load();
        //EM.resourceRequests.load();

        this.calculatePrecedence = this.calculatePrecedence.bind(this);
        this.sortByPrecedence = this.sortByPrecedence.bind(this);
        this.onCloseExpresssionEditor = this.onCloseExpresssionEditor.bind(this);
        this.onSaveExpresssion = this.onSaveExpresssion.bind(this);        
    }

    onCloseExpresssionEditor(){
        if (this.state.currentExpressionEditorProps){
            this.state.currentExpressionEditorProps.onBlur();
        }
        this.setState({ isExpressionEditorOpen: false, currentExpressionRow: null, currentExpressionEditorProps: null });
    }

    onSaveExpresssion(modifiedExpressionText){
        if (this.state.currentExpressionEditorProps && modifiedExpressionText){
            this.state.currentExpressionEditorProps.onUpdate(modifiedExpressionText);
        }
        this.onCloseExpresssionEditor();
    }

    calculatePrecedence(cell, row){
        let prec = 0;
        if (row.WorkItemName)prec += 1;
        if (row.ActivityId)prec += 1;
        if (row.RoleId)prec += 1;
        if (row.AttributeKey || row.AttributeValue)prec += 1;
        return prec;
    }

    sortByPrecedence(a, b, order, dataField, rowA, rowB){    
        var self = this;
        let aP = self.calculatePrecedence(null, rowA);
        let bP = self.calculatePrecedence(null, rowB);
        if (order === 'desc') {
            return parseInt(aP) - parseInt(bP);
        } else {
            return parseInt(bP) - parseInt(aP);
        }
    } 

    render() {
        let data = null;
        let columns = null;
        const entity = EM.algorithms;

        if (EM.allLoaded(entity, EM.activities, EM.roles, EM.complexityLookup)){    
            data = entity.get();
            columns = [{
                dataField: 'WorkItemName',
                text: entity.columns('workItemTitle'),
                asWorkItem: true,
                width: 150,
                filterOptions: {
                    text: true
                }
            }, {
                dataField: 'ActivityId',
                text: entity.columns('activity'),
                fromEntity: EM.activities,
                allowNulls: true,
                width: 150
            }, {
                dataField: 'RoleId',
                text: entity.columns('role'),
                fromEntity: EM.roles,
                allowNulls: true,
                width: 150
            }, {
                dataField: 'AttributeKey',
                text: entity.columns('otherKey'),
                width: 150,
                filterOptions: {
                    text: true
                }   
            }, {
                dataField: 'AttributeValue',
                text: entity.columns('otherValue'),
                width: 150,
                filterOptions: {
                    text: true
                }
            }, {
                dataField: 'Expression',
                text: entity.columns('expression'),
                width: 500,
                asExpression: true,
                onStartEdit: (editorProps, value, row, column, rowIndex, columnIndex) => {
                    window.setTimeout(() => {
                        this.setState({ isExpressionEditorOpen: true, currentExpressionRow: row, currentExpressionEditorProps: editorProps });
                    }, 0);
                },
                validators: [ Validators.required ],                
                filterOptions: {
                    text: true
                }
            }, {
                dataField: 'precedence',
                text: entity.columns('precedence'),
                isReference: true,
                formatter: this.calculatePrecedence,
                sortFunc: this.sortByPrecedence,
                width: 100,
                filterValue: this.calculatePrecedence,                
                filterOptions: {
                    number: true
                }
            }, {
                dataField: 'Active',
                text: entity.columns('active'),
                asActive: true,
                width: 75
            }, {
                dataField: 'Description',
                text: entity.columns('description'),
                width: 400,
                asDescription: true,
                filterOptions: {
                    text: true
                }  
            }];
        }
    
        let algoObject = this.state.currentExpressionRow;
        let useNewEditor = EM.getSetting('UseNewAlgoEditor') !== 'false';

        let allowAlgoImport = EM.isSuperAdmin();
        let allowAlgoImportSetting = EM.getTenantOption('allowAlgoImportInDomains');
        if (allowAlgoImportSetting){
            let allowedDomainIds = allowAlgoImportSetting.split(',');
            let activeDomainObject = EM.getActiveDomain();
            if (allowedDomainIds.indexOf(activeDomainObject.DomainId) > -1){
                allowAlgoImport = true;
            }
        }

        return (
            <div key="contents" className="page container-fluid">
                <EntityTable 
                    entity={entity} 
                    data={data} 
                    columns={columns} 
                    hideImport={!allowAlgoImport} 
                    className="table-overflow-container"
                />

                {algoObject && useNewEditor ?
                    <Suspense>
                        <ExpressionDialog2 
                            expression={algoObject.Expression} 
                            title={EM.t('algorithms.expressions.sourceEditor') + ' (ID: ' + algoObject.MultiplierId + ')'}
                            isOpen={this.state.isExpressionEditorOpen} 
                            onClose={this.onCloseExpresssionEditor} 
                            onSave={this.onSaveExpresssion}
                        />
                    </Suspense>
                :
                    <ExpressionDialog 
                        algoObject={algoObject} 
                        isOpen={this.state.isExpressionEditorOpen} 
                        onClose={this.onCloseExpresssionEditor} 
                        onSave={this.onSaveExpresssion}
                    />
                }   
            </div>
        );
    }
}