import ScheduleFileType from './files/ScheduleFile';
import ProjectionsFileType from './files/ProjectionsFile';
import HeadcountsFileType from './files/HeadcountsFile';
import ScenariosFileType from './files/ScenariosFile';
import ActualsFileType from './files/ActualsFile';
import NotesFile from './files/NotesFile';
import ProjectionsEntity from './ProjectionsEntity';
import AdminEntity from './AdminEntity';
import ActivitiesEntity from './ActivitiesEntity';
import EmployeesEntity from './EmployeesEntity';
import TenantEntity from './TenantEntity';
import ConfigurationsEntity from './ConfigurationsEntity';
import BudgetsEntity from './BudgetsEntity';
import BudgetsFileBase from './files/BudgetsFileBase';

export default [
    {
        name: 'activities', 
        idField: 'ActivityId',
        uniqueness: [ 'Name' ],
        entityType: ActivitiesEntity
    },
    {
        name: 'actuals', 
        idField: 'ActualId',
        isFileListingTable: true,
        fileTypeClass: ActualsFileType,
        uniqueness: [ 'Name' ],
        modelNameSingular: 'Actuals'
    },      
    {
        name: 'allocations', 
        idField: 'AllocationId',
        uniqueness: [ 'RoleId', 'ActivityId' ]
    },
    {
        name: 'assignments', 
        idField: 'AssignmentId',
        uniqueness:['AssignmentId']
    },           
    {
        name: 'attributes', 
        idField: 'AttributeId',
        uniqueness: [ 'AttributeTypeLookupId', 'Scope', 'Name' ]
    }, 
    {
        name: 'attributeTypeLookup', 
        idField: 'AttributeTypeLookupId',
        uniqueness: [ 'Key' ]
    },       
    {
        name: 'complexities', 
        idField: 'ComplexityId',
        uniqueness: [ 'RoleId', 'ActivityId', 'WorkItemName', 'AttributeKey', 'AttributeValue' ]
    },
    {
        name: 'complexityLookup',
        idField: 'ComplexityLookupId'
    },
    {
        name: 'configurations', 
        idField: 'ConfigurationId',
        uniqueness: [ 'Name' ],
        entityType: ConfigurationsEntity
    },          
    {
        name: 'configurationTypeLookup',
        idField: 'ConfigurationTypeLookupId',
        uniqueness: [ 'Name' ]
    },
    {
        name: 'departments',
        idField: 'DepartmentId',
        uniqueness: [ 'Name' ]
    },
    {
        name:'domains',
        idField:'DomainId',
        uniqueness:[ 'Name' ],
        entityType: AdminEntity
    },         
    {
        name: 'employees',
        idField: 'EmployeeId',
        uniqueness: [ 'FirstName', 'LastName', 'RoleId' ],
        entityType: EmployeesEntity,
        allowDuplicates: true
    }, 
    {
        name: 'headcounts', 
        idField: 'HeadcountId',
        isFileListingTable: true,
        fileTypeClass: HeadcountsFileType,
        uniqueness: [ 'Name' ]
    },
    {
        name: 'notes', 
        idField: 'NoteId',
        isFileListingTable: true,
        fileTypeClass: NotesFile,
        uniqueness: ['NoteId']
    },    
    {
        name: 'algorithms',
        modelName: 'multipliers',
        idField: 'MultiplierId',
        uniqueness: [ 'RoleId', 'ActivityId', 'WorkItemName', 'AttributeKey', 'AttributeValue' ]
    },        
    {
        name: 'organizations',
        idField: 'OrganizationId',
        uniqueness: [ 'Name' ]
    },
    {
        name:'permissionLookup',
        idField:'PermissionLookupId',
        uniqueness:[ 'Key' ]
    },
    {
        name:'permissions',
        idField:'PermissionId',
        uniqueness:[ 'UserId' ]
    },                  
    {
        name: 'projections', 
        idField: 'ProjectionId',
        isFileListingTable: true,
        entityType: ProjectionsEntity,
        fileTypeClass: ProjectionsFileType,
        uniqueness: [ 'ProjectionId' ]
    },    
    {
        name: 'referenceTables', 
        idField: 'ReferenceTableId',
        uniqueness: [ 'Name' ]
    },    
    {
        name: 'roles', 
        idField: 'RoleId',
        uniqueness: [ 'Name' ]
    },
    {
        name: 'scenarios', 
        idField: 'ScenarioId',
        fileTypeClass: ScenariosFileType,        
        isFileListingTable: true,
        uniqueness: [ 'Name' ]
    },    
    {
        name: 'schedules', 
        idField: 'ScheduleId',
        isFileListingTable: true,
        fileTypeClass: ScheduleFileType,
        uniqueness: [ 'Name' ]
    },           
    {
        name: 'settings', 
        idField: 'SettingId',
        uniqueness: [ 'Key' ]
    },
    {
        name:'tenants',
        idField:'TenantId',
        uniqueness:[ 'TenantId' ],
        entityType: TenantEntity
    },
    {
        name:'users',
        idField:'UserId',
        uniqueness:[ 'Email' ],
        entityType: AdminEntity
    },
    {
        name: 'resourceRequests', 
        idField: 'ResourceRequestId',
        uniqueness: [ 'WorkItem' ]
    },
    {
        name: 'averageWorkingHours', 
        idField: 'AverageWorkingHourId',
        uniqueness: [ 'Country' ],
    },
    {
        name: 'inflationFactors', 
        idField: 'InflationFactorId',
        uniqueness: [ 'Country' ]
    },
    {
        name: 'countryCurrencies', 
        idField: 'CountryCurrencyId',
        uniqueness: [ 'Country' ],
        entityType: AdminEntity
    },
    {
        name: 'currencyConversions', 
        idField: 'CurrencyConversionId',
        uniqueness: [ 'CurrencyCodeId' ],
    },
    {
        name: 'currencyCodes', 
        idField: 'CurrencyCodeId',
        uniqueness: [ 'CurrencyCodeId' ],
        entityType: AdminEntity
    },
    {
        name: 'rateCards', 
        idField: 'RateCardId',
        uniqueness: [  'RoleId', 'CountryCurrencyId', 'HourlyRate', 'Year' ]
    },
    {
        name: 'budgets', 
        idField: 'BudgetId',
        uniqueness: [ 'BudgetId' ],
        isFileListingTable: true,
        entityType: BudgetsEntity,
        fileTypeClass: BudgetsFileBase,
    }
]