/* global EM */
import { Workbook } from '../util/Workbook';
import PipelineRunner from '../entities/pipelines/PipelineRunner';
import CSV from '../util/CSV';

export default {
    async exportAllData(){
        let allDataSets = [
            EM.organizations, 
            EM.departments, 
            EM.roles, 
            EM.activities, 
            EM.allocations, 
            EM.complexities, 
            EM.attributes, 
            EM.algorithms, 
            EM.employees,
            EM.assignments,
            EM.settings,
            EM.schedules, 
            EM.projections,
            EM.budgets
        ];
        EM.loadEntities(allDataSets).then(async () => {
            let schedule = null;
            let projections = null;

            let defaultSchedule = await EM.schedules.loadDefaultItem();
            if (defaultSchedule){
                await EM.schedules.loadFile(defaultSchedule.ScheduleId);        
                schedule = EM.schedules.getFile(defaultSchedule.ScheduleId);                    
                
                let domain = EM.getActiveDomain();
                let domainIdToSearch = domain.ParentDomainId ? domain.ParentDomainId : domain.DomainId;
                if (EM.projections.isOverridden())domainIdToSearch = domain.DomainId;
                let newestProjections = defaultSchedule.Projections.find((projection) => {
                    return projection.Status.toLowerCase().indexOf('success') === 0 && projection.DomainId === domainIdToSearch;
                });

                if (newestProjections){                    
                    let pid = newestProjections.ProjectionId;
                    await EM.projections.loadFile(pid, false);
                    projections = EM.projections.getFile(pid);
                }
            }

            new Workbook(async (wb) => {
                for(let i = 0, j = allDataSets.length; i < j; i++){
                    let entity = allDataSets[i];
                    let tabName = entity.t('title');
                    try{
                        if (tabName === 'Schedules' || tabName === 'Projections'){
                            if (tabName === 'Schedules')tabName = 'Schedules List';
                            if (tabName === 'Projections')tabName = 'Projections List';
                            let tbl = await PipelineRunner.entityToTable(entity);
                            wb.addWorksheetFromJsonFlatArray(tabName, tbl);
                        }else{                    
                            let tbl = await PipelineRunner.entityToTable(entity);
                            let output = await PipelineRunner.output(entity, tbl);
                            if (!tbl || !output)continue;
                            wb.addWorksheetFromJsonFlatArray(tabName, output);
                        }   
                    }catch(e){
                        console.warn('Error exporting ' + tabName, e.message);
                    }                 
                }

                try{
                    if (schedule) wb.addWorksheetFromJsonFlatArray('Schedule', await this.processPipeline(EM.schedules, schedule.data));
                }catch(e){
                    console.warn('Error exporting Schedule', e.message);
                } 

                try{
                    if (projections) wb.addWorksheetFromJsonFlatArray('Projections', await this.processPipeline(EM.projections, projections.data));
                }catch(e){
                    console.warn('Error exporting Projections', e.message);
                } 
                wb.save('full-export');
            });
        });
    },

    async exportProjections(name, projectionId){
        await EM.projections.loadFile(projectionId, false);
        await EM.activities.load();
        await EM.roles.load();
        let projections = EM.projections.getFile(projectionId);
        if (!projections)return;
        CSV.save(name + '-Projections',  await this.processPipeline(EM.projections, projections.data));
    },

    async exportBudgets(name, budgetId){
        await EM.budgets.loadFile(budgetId, false);
        let budgets = EM.budgets.getFile(budgetId);
        if (!budgets) return;
        const headers = Object.keys(budgets.data[0]);
        const rows = budgets.data.map(item => {
            return headers.map(header => item[header] || ''); 
        });
        const finalData = [headers, ...rows];
        CSV.save(name + '-Budgets', finalData);
    },
    
    async exportEntity(entity, suffix){
        let data = await PipelineRunner.entityToTable(entity);
        let output = await PipelineRunner.output(entity, data);
        CSV.save(entity.name + (suffix||''),  output);
    },

    async processPipeline(entity, csvContents){
        let output = csvContents;
        try{
            output = await PipelineRunner.output(entity, output);
        }catch(e){
            console.warn(e);
        }
        return output;
    }
}