/* global EM */
import React, { Component } from 'react';
import EntityTable from '../components/EntityTables/EntityTable';
import { Validators } from '../util/EntityValidators';

class Activities extends Component {
    constructor(props){
        super(props);

        EM.activities.load();
        //EM.resourceRequests.load();
        
        this.activityLength = EM.getTenantOption('activityLength') || '50';
    }

    render() {    
        let data = null;
        let columns = null;
        const entity = EM.activities;        

        if (EM.allLoaded(entity)){    
            data = entity.get();
            columns = [{
                dataField: 'Name',
                text: entity.columns('name'),
                validators: [ Validators.required, (this.activityLength === '100' ? Validators.length100 : Validators.length50) ],
                filterOptions: {
                    text: true
                }
            }, {
                dataField: 'Order',
                text: entity.columns('order'),
                width: 75,
                validators: [ Validators.requiredNumber, Validators.numberOnly ],
                filterOptions: {
                    number: true
                }
            }];
        }

        return (
            <div key="contents" className="page container-fluid">
                <EntityTable entity={entity} data={data} columns={columns} className="table-overflow-container"/>
            </div>
        );
    }
}

export default Activities;
